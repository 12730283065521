import { createServiceManifest } from '@estee/elc-service';
import {
    SEARCH_RESULTS,
    SEARCH_GNAV_INPUT_FIELD,
    VOICE_SEARCH
} from '@estee/elc-service-view-names';
import { IManifestServiceRegistryComp } from '@estee/elc-universal-utils';
import { SearchService } from './service-setup/SearchService';

const { name, version } = __serviceInfo__;

const serviceViews = [SEARCH_RESULTS, SEARCH_GNAV_INPUT_FIELD, VOICE_SEARCH];
const serviceEvents: string[] = [];
const serviceQueries: string[] = [];

export const ServiceManifest: IManifestServiceRegistryComp = createServiceManifest<SearchService>(
    {
        views: serviceViews,
        events: serviceEvents,
        queries: serviceQueries,
        name,
        version
    },
    () =>
        import(
            /* webpackChunkName: 'core' */
            './service-setup/diContainer'
        ),
    'searchService'
);
